.node {
    width: 50px;
}

@media (max-width: 600px) {
    .node {
        width: 40px;
    }
}

.nodeButton {
    opacity: 0.7;
    cursor: pointer;
    position: absolute;
    background: 0;
    border: 0;
    transition: ease 0.2s;
}

.nodeButton:hover {
    opacity: 1;
}

.nodeButton:focus {
    outline: none;
}
